body {
    background: #8e44ad linear-gradient(120deg, #2980b9, #8e44ad);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
}

.panel-login {
    padding: 4em;
    background-color: white;
    display: flex;
    flex-direction: column;
    min-width: 500px;
    text-align: center;
    gap: 2em;
    border-radius: 2em;
}

.panel-login h1 {
    font-size: 3.5em;
    margin: 0;
}

.panel-login hr {
    border-color: #ddd;
    border-width: 1px;
}

.panel-login input {
    font-size: 1.5em;
    padding: 0.6em;
    border: none;
    border-bottom: 3px solid #ccc;
}

.panel-login input:focus {
    outline: none;
    border-color: #208cd4;
}

.panel-login input::placeholder {
    color: #bebebe;
}

.panel-login a {
    font-size: 1.5em;
    color: #bebebe;
    text-decoration: none;
}

.panel-login a:visited {
    text-decoration: none;
    color: #bebebe;
}

.panel-login a:hover {
    color: #208cd4;
    text-decoration: none;
}

.panel-login button {
    font-size: 1.5em;
    padding: 1em;
    border-radius: 2em;
    background: #208cd4;
    color: white;
    border: none;
    cursor: pointer;
}

.panel-login button:hover {
    background-color: #208cd4aa;
}