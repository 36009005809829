.main {
    background: white;
    width: 100vw;
    height: 100vh;
    display: flex;
}

.main.is-uploading::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff3 url(../assets/uploading.gif) no-repeat center;
}

.gallery {
    background: #f5f4fa;
    position: relative;
}

.gallery > h1 {
    padding-left: 60px;
    display: flex;
    gap: 0.8em;
    color: #444;
}

.gallery > h1 img {
    height: 1.6em;
}

.gallery > button {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 60px;
    border-radius: 50%;
    border: 2px solid white;
    color: #aaa;
    cursor: pointer;
    background: #ddd;
}

.gallery > button:hover {
    background: #ccc;
    color: white;
}

.gallery > button.btn-scroll-left {
    left: 10px;
}

.gallery > button.btn-scroll-right {
    right: 10px;
}

.image-gallery {
    width: calc(100vw - 300px);
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 220px);
}

.image-gallery-slide {
    height: calc(100vh - 220px);
    display: flex !important;
    align-items: center;
}

.image-gallery-slide-wrapper {
    background: black;
    border-radius: 10px;
    margin: 0 60px;
    overflow: hidden;
}

.image-gallery-thumbnails-wrapper {
    padding: 10px 60px;
}

.image-gallery-thumbnail {
    border-radius: 10px;
    overflow: hidden;
    border: none;
    height: 80px;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
    border-width: 4px;
}

.image-gallery-thumbnail.active {
    border-color: darkred;
}

.image-gallery-thumbnail {
    margin: 0 5px !important;
}

.image-gallery-thumbnail span {
    background-color: black;
    height: 80px;
    display: flex !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.tools {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.5em 2em;
}

.tools .panel-user {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 3em;
}

.tools .btn-logout {
    width: 24px;
    height: 24px;
    background: transparent url(../assets/logout.png) no-repeat center;
    background-size: contain;
    border: none;
    cursor: pointer;
}

.tools .btn-logout:hover {
    background: transparent url(../assets/logout-hover.png) no-repeat center;
    background-size: contain;
}

.tools h3 {
    color: #555;
}

.tools .panel-reserve {
    height: 100%;
}

.clients .Dropdown-control {
    background-color: #f5f4fa;
    border: none;
    border-radius: 14px;
    color: black;
    background: #f5f4fa url(../assets/user.png) no-repeat 0.5em center;
    background-size: auto 2em;
    padding: 1em 3em 1em;
}

.clients.is-open .Dropdown-control {
    border-radius: 14px 14px 0 0;
}

.clients .Dropdown-arrow {
    top: calc(50% - 2px);
}

.clients .Dropdown-menu {
    background: #f5f4fa;
    border: none;
    border-radius: 0 0 14px 14px;
}

.clients .Dropdown-option.is-selected {
    background: rgb(223,32,58);
    color: white;
}

.clients .Dropdown-option:hover:not(.is-selected) {
    background: rgba(223,32,58, 0.2);
}


.panel-tools > div {
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
}

.panel-tools button {
    flex-grow: 1;
    height: 3rem;
    border-radius: 14px;
    border: none;
    background-color: #f5f4fa;
    cursor: pointer;
    position: relative;
}

.panel-tools button:disabled {
    opacity: 0.5;
}

.panel-tools button:not(:disabled):hover {
    background: rgb(223,32,58);
    background: -moz-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    color: white;
    font-size: large;
}

.panel-tools button::after {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    content: '';
}

.panel-tools button:not(:disabled):hover::after {
    filter: invert(100%);
}

.panel-tools .btn-save::after {
    background: url(../assets/save.png) no-repeat center;
    background-size: auto 2.5rem;
}

.panel-tools .btn-delete::after {
    background: url(../assets/trash.png) no-repeat center;
    background-size: auto 2.5rem;
}

.panel-tools .btn-upload::after {
    background: url(../assets/upload.png) no-repeat center;
    background-size: auto 2.5rem;
}

.panel-display > div {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.panel-display button {
    height: 3rem;
    border-radius: 14px;
    border: none;
    background-color: #f5f4fa;
    cursor: pointer;
}

.panel-display button:hover, .panel-display button.selected {
    background: rgb(223,32,58);
    background: -moz-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    color: white;
    font-size: large;
}

.dropzone {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fffd;
    /* display: none; */
}

.dropzone h1 {
    /* font-size: 2rem; */
    background: -moz-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    background: linear-gradient(180deg, rgba(223,32,58,1) 0%, rgba(160,11,185,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* text-shadow: 0 0 4px black; */
}

.dropzone-intro {
    padding-top: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* display: none; */
}